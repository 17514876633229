/* 
  Created by develop_tu at 03-21-2022 21:32:57
  Module product
*/
import { CalendarOutlined, ReconciliationOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import React, { lazy } from 'react';
import { IRoute } from 'types/Route';

const ContentLayout = lazy(() => import('../../libraries/layouts/content.layout'));
const WidgetScreen = lazy(() => import('modules/widget/landing_page_widget.screen'));
const WidgetInfoScreen = lazy(() => import('modules/widget-info/landing_page_widget.screen'));

export const langding_page_Route: IRoute = {
    path: '/landing_page',
    name: 'landing_page.menu',
    existSubMenu: true,
    component: ContentLayout,
    icon: <ReconciliationOutlined />,
    code: 'landing_page',
    routes: [
        {
            path: '/landing_page/list_info',
            name: 'landing_page.list_info',
            component: WidgetInfoScreen,
            exact: true,
            code: 'landing_page'
        },
        {
            path: '/landing_page/list_widget',
            name: 'landing_page.list_widget',
            component: WidgetScreen,
            exact: true,
            code: 'landing_page'
        }

    ]
};
