export default {
    ...require('./vi.shared.json'),
    ...require('../../modules/account/point-signup/languages/vi.point-signup.json'),
    ...require('../../modules/product-point-rule/languages/vi.product-point-rule.json'),
    ...require('../../modules/product-icon/languages/vi.icon.json'),
    ...require('../../modules/footer/guide/languages/vi.guide.json'),
    ...require('../../modules/banner/languages/vi.banner.json'),
    ...require('../../modules/footer/languages/vi.footer.json'),
    ...require('../../modules/faq/message/languages/vi.message.json'),
    ...require('../../modules/user-manager/components/general/components/order-history/languages/vi.order-history.json'),
    ...require('../../modules/collection/languages/vi.collection.json'),
    ...require('../../modules/coupon/languages/vi.coupon.json'),
    ...require('../../modules/order-manager/languages/vi.order-manager.json'),
    ...require('../../modules/faq/languages/vi.faq.json'),
    ...require('../../modules/category/languages/vi.category.json'),
    ...require('../../modules/user-manager/res/translations/vi.user-manager.json'),
    ...require('../../modules/admin-manager/languages/vi.admin-manager.json'),
    ...require('../../modules/product-color/res/translations/vi.color.json'),
    ...require('../../modules/product/languages/vi.product.json'),
    ...require('../../modules/product-material/languages/vi.product-material.json'),
    ...require('../../modules/product-size/languages/vi.product-size.json'),
    ...require('../../modules/sale/languages/vi.sale.json'),
    ...require('../../modules/warehouse/languages/vi.warehouse.json'),
    ...require('../../modules/messages/languages/vi.messages.json'),
    ...require('../../modules/warehouse/shipping/languages/vi.shipping.json'),
    ...require('../../modules/auth/res/translations/vi.auth.json'),
    ...require('../../modules/rank/languages/vi.rank.json'),
    ...require('../../modules/introduction-rule/languages/vi.introduction-rule.json'),
    ...require('../../modules/report/languages/vi.report.json'),
    ...require('../../modules/admin-authorization/languages/vi.admin-authorization.json'),
    ...require('../../modules/choose-product/languages/vi.choose-product.json'),
    ...require('../../modules/home-product/languages/vi.messages.json'),
    ...require('../../modules/payment/languages/vi.messages.json'),
    ...require('../../modules/introduce/languages/vi.messages.json'),
    ...require('../../modules/policy/languages/vi.messages.json'),
    ...require('../../modules/blog/languages/vi.messages.json'),
    ...require('../../modules/news/languages/vi.messages.json'),
    ...require('../../modules/settings/languages/vi.messages.json'),
    ...require('../../modules/partner/languages/vi.messages.json'),
    ...require('../../modules/widget/languages/vi.landing.json'),
    ...require('../../modules/widget-info/languages/vi.widget_info.json'),
};
