export interface BaseEvent {
    type: EventBusName;
}

export interface BaseEventPayload<Payload> {
    type: EventBusName;
    payload: Payload;
}

export enum EventBusName {
    LOGOUT_EVENT,
    SHOW_MODAL_CONFIRM_EVENT,
    SHOW_MODAL_ADD_CATEGORY_EVENT,
    SHOW_MODAL_PRODUCT_MODAL_EVENT,
    SHOW_IMPORT_PRODUCT_MODAL_EVENT,
    HIDE_MODAL_CONFIRM_EVENT,
    HIDE_LOADING_CONFIRM_EVENT,
    SHOW_MODAL_ADD_BANNER_EVENT,
    RESET_IMAGE,
    JOIN_CONVERSATION,
    LEAVE_CONVERSATION,
    TRACKING_NEW_MESSAGE,
    ON_TRACKING_NEW_MESSAGE,
    SEND_MESSAGE,
    SHOW_IMPORT_MENU_MODAL_EVENT,
    ON_SEND_MESSAGE, 
    SHOW_MODAL_ADD_PRICE_CTV,
    LOAD_NEW_MESSAGE,
    ON_LOAD_NEW_MESSAGE,
    LOAD_CONVERSATION,
    ON_LOAD_CONVERSATION,
    RESOLVE_PROBLEM,
    ON_NEW_LOG,
    JOIN_LOG
}
