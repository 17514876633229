/* 
  Created by vungbt at 06-26-2022 22:25:25
  Module messages
*/
import { MessageOutlined } from '@ant-design/icons';
import React, { lazy } from 'react';
import { IRoute } from 'types/Route';

const MessagesScreen = lazy(() => import('modules/messages/messages.screen'));

const ContentLayout = lazy(() => import('../../libraries/layouts/content.layout'));

const MessagesRoute: IRoute = {
    path: '/messages',
    name: 'messagesPage.menu',
    component: ContentLayout,
    icon: <MessageOutlined />,
    routes: [
        {
            path: '/messages',
            name: 'messagesPage.title',
            component: MessagesScreen,
            exact: true
        }
    ]
};

export default MessagesRoute;
