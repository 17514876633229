import { lazy } from 'react';
import { IRoute } from 'routing/route.types';

const LoginScreen = lazy(() => import('../../modules/auth/login.screen'));
const ForgotPasswordScreen = lazy(() => import('../../modules/auth/forgot-password.screen'));
const OtpScreen = lazy(() => import('../../modules/auth/otp.screen'));
const ResetPassWordScreen = lazy(() => import('../../modules/auth/reset-password.screen'));

const AuthLayout = lazy(() => import('../../libraries/layouts/auth.layout'));

export const AuthRoute: IRoute = {
    path: '/auth',
    name: 'auth',
    component: AuthLayout,
    hideInMenu: true,
    routes: [
        {
            path: '/auth/login',
            name: 'login',
            component: LoginScreen,
            exact: true
        },
        {
            path: '/auth/forgot-password',
            name: 'forgot_password',
            component: ForgotPasswordScreen,
            exact: true
        },
        {
            path: '/auth/otp',
            name: 'otp',
            component: OtpScreen,
            exact: true
        },
        {
            path: '/auth/reset-password',
            name: 'reset_password',
            component: ResetPassWordScreen,
            exact: true
        }
    ]
};
