/* 
  Created by develop_tu at 03-21-2022 21:32:57
  Module product
*/
import { ShopOutlined } from '@ant-design/icons';
import React, { lazy } from 'react';
import { IRoute } from 'types/Route';

const ProductScreen = lazy(() => import('modules/product/product.screen'));
const ProductViewedScreen = lazy(() => import('modules/product/product-viewed.screen'));
const ProductDetailScreen = lazy(() => import('modules/product/product-detail.screen'));
const ContentLayout = lazy(() => import('../../libraries/layouts/content.layout'));

const ColorScreen = lazy(() => import('modules/product-color/color.screen'));
const ColorDetailScreen = lazy(() => import('modules/product-color/color-detail.screen'));

const CategoryScreen = lazy(() => import('modules/category/category.screen'));

const ProductMaterialScreen = lazy(
    () => import('modules/product-material/product-material.screen')
);

const ProductSizeScreen = lazy(() => import('modules/product-size/product-size.screen'));

const IconScreen = lazy(() => import('modules/product-icon/icon.screen'));

const ProductPointRuleScreen = lazy(
    () => import('modules/product-point-rule/product-point-rule.screen')
);

const PhooseProductScreen = lazy(() => import('modules/choose-product/choose-product.screen'));

const HomeProductScreen = lazy(() => import('modules/home-product/home-product.screen'));

export const ProductRoute: IRoute = {
    path: '/product',
    name: 'product.menu',
    existSubMenu: true,
    component: ContentLayout,
    icon: <ShopOutlined />,
    routes: [
        {
            path: '/product/list',
            name: 'product.title',
            component: ProductScreen,
            exact: true
        },
        {
            path: '/product/add',
            name: 'product.textAdd',
            component: ProductDetailScreen,
            hideInMenu: true,
            exact: true
        },
        {
            path: '/product/category',
            name: 'category.menu',
            component: CategoryScreen,
            exact: true
        },
        {
            path: '/product/edit/:id',
            name: 'product.textDetail',
            component: ProductDetailScreen,
            hideInMenu: true,
            exact: true,
            strict: true
        },
        {
            path: '/product/viewed/:id',
            name: 'product.viewedByCustomers',
            component: ProductViewedScreen,
            exact: true,
            strict: true,
            hideInMenu: true
        },
        // end product-color

        // product-material
        // {
        //     path: '/product/material',
        //     name: 'productMaterial.menu',
        //     component: ProductMaterialScreen,
        //     exact: true,
        //     strict: true
        // },
        // end product-material

        // product-size
        // {
        //     path: '/product/size',
        //     name: 'productSize.menu',
        //     component: ProductSizeScreen,
        //     exact: true,
        //     strict: true
        // },
        // end product-size

        // icon
        // {
        //     path: '/product/icon',
        //     name: 'icon.menu',
        //     component: IconScreen,
        //     exact: true
        // },

        // point

        {
            path: '/product/point',
            name: 'productPointRule.menu',
            component: ProductPointRuleScreen,
            exact: true
        },

        // {
        //     path: '/product/choose-product',
        //     name: 'choose-product.menu',
        //     component: PhooseProductScreen,
        //     exact: true
        // },
        {
            path: '/product/home-product',
            name: 'homeProduct.menu',
            component: HomeProductScreen,
            exact: true
        }
    ]
};
