import { MoneyCollectOutlined } from '@ant-design/icons';
import React, { lazy } from 'react';
import { IRoute } from 'types/Route';

const PaymentScreen = lazy(() => import('modules/payment/payment.screen'));

const ContentLayout = lazy(() => import('../../libraries/layouts/content.layout'));

const PaymentRoute: IRoute = {
    path: '/payment',
    name: 'payment.menu',
    component: ContentLayout,
    icon: <MoneyCollectOutlined />,
    routes: [
        {
            path: '/payment',
            name: 'payment.title',
            component: PaymentScreen,
            exact: true
        }
    ]
};

export default PaymentRoute;
