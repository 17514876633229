/* 
  Created by xhoang at 06-08-2022 11:34:25
  Module coupon
*/
import { GiftOutlined } from '@ant-design/icons';
import React, { lazy } from 'react';
import { IRoute } from 'types/Route';

const CouponScreen = lazy(() => import('modules/coupon/coupon.screen'));
const BannerScreen = lazy(() => import('modules/banner/banner.screen'));

const ContentLayout = lazy(() => import('../../libraries/layouts/content.layout'));

export const CouponRoute: IRoute = {
    path: '/coupon',
    name: 'coupon.title',
    component: ContentLayout,
    icon: <GiftOutlined />,
    existSubMenu: true,
    routes: [
        {
            path: '/coupon/list',
            name: 'coupon.menu',
            component: CouponScreen,
            exact: true
        },
        {
            path: '/coupon/banner',
            name: 'banner.menu',
            component: BannerScreen
        }
    ]
};
