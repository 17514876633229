/* 
  Created by xhoang at 05-18-2022 09:03:55
  Module admin-manager
*/
import { UserOutlined } from '@ant-design/icons';
import React, { lazy } from 'react';
import { IRoute } from 'types/Route';

const AdminManagerScreen = lazy(() => import('modules/admin-manager/admin-manager.screen'));
const UserManagerScreen = lazy(() => import('modules/user-manager/user-manager.screen'));

const AdminManagerDetailScreen = lazy(
    () => import('modules/admin-manager/admin-manager-detail.screen')
);
const AdminManagerGeneralScreen = lazy(
    () => import('modules/admin-manager/admin-manager-general.screen')
);
const UserManagerDetailScreen = lazy(
    () => import('modules/user-manager/user-manager-detail.screen')
);

const UserGeneral = lazy(() => import('modules/user-manager/components/general'));

const ContentLayout = lazy(() => import('../../libraries/layouts/content.layout'));

const PointSignupScreen = lazy(() => import('modules/account/point-signup/point-signup.screen'));
const IntroductionRuleScreen = lazy(() => import('modules/introduction-rule/introduction-rule.screen'));

export const AccountManagerRoute: IRoute = {
    path: '/manager',
    name: 'menu_account',
    component: ContentLayout,
    icon: <UserOutlined />,
    existSubMenu: true,
    routes: [
        // admin-manager
        {
            path: '/manager/admin',
            name: 'menuAdmin',
            component: AdminManagerScreen,
            exact: true
        },
        {
            path: '/manager/admin/add',
            name: 'admin-manager.textAdd',
            component: AdminManagerDetailScreen,
            hideInMenu: true,
            exact: true
        },
        {
            path: '/manager/admin/general',
            name: 'admin-manager.textDetail',
            component: AdminManagerGeneralScreen,
            hideInMenu: true,
            exact: true,
            strict: true
        },
        {
            path: '/manager/admin/:id',
            name: 'admin-manager.edit',
            component: AdminManagerDetailScreen,
            hideInMenu: true,
            exact: true
        },
        // user-manager
        {
            path: '/manager/user',
            name: 'menuUser',
            component: UserManagerScreen,
            exact: true
        },
        {
            path: '/manager/user/add',
            name: 'user-manager.textAdd',
            component: UserManagerDetailScreen,
            hideInMenu: true,
            exact: true
        },
        {
            path: '/manager/user/:id',
            name: 'user-manager.textDetail',
            component: UserManagerDetailScreen,
            hideInMenu: true,
            exact: true
        },
        {
            path: '/manager/user/general/:id',
            name: 'user-manager.textDetail',
            component: UserGeneral,
            hideInMenu: true,
            exact: true
        },
        // point-signup
        {
            path: '/manager/point-signup',
            name: 'pointSignup.title',
            component: PointSignupScreen,
            exact: true
        },
        {
            path: '/manager/introduction-rule',
            name: 'introductionRule.introduction',
            component: IntroductionRuleScreen,
            exact: true
        }
    ]
};
