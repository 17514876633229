/* 
  Created by Lê Quang Huy at 06-02-2022 08:00:36
  Module order-manager
*/
import { ShoppingCartOutlined } from '@ant-design/icons';
import React, { lazy } from 'react';
import { IRoute } from 'types/Route';

const OrderManagerScreen = lazy(() => import('modules/order-manager/order-manager.screen'));

const OrderManagerDetailScreen = lazy(
    () => import('modules/order-manager/order-manager-detail.screen')
);

const OrderManagerAddScreen = lazy(
    () => import('modules/order-manager/order-manager-add.screen')
);

// const OrderManagerAddScreen = lazy(() => import('modules/order-manager/order-manager-add.screen'));

const ContentLayout = lazy(() => import('../../libraries/layouts/content.layout'));

export const OrderManagerRoute: IRoute = {
    path: '/order-manager',
    name: 'orderManager.menu',
    component: ContentLayout,
    icon: <ShoppingCartOutlined />,
    routes: [
        {
            path: '/order-manager', // danh sacsh
            name: 'orderManager.title',
            component: OrderManagerScreen,
            exact: true
        },
        {
            path: '/order-manager/detail/:id', // chi tiet
            name: 'orderManager.textDetail',
            component: OrderManagerDetailScreen,
            hideInMenu: true,
            exact: true
        },
        {
            path: '/order-manager/add', // chi tiet
            name: 'orderHistory.textAdd',
            component: OrderManagerAddScreen,
            hideInMenu: true,
            exact: true
        }
    ]
};
