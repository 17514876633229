/* 
  Created by Thuan at 06-02-2022 08:20:01
  Module faq
*/
import { ReadOutlined } from '@ant-design/icons';
import React, { lazy } from 'react';
import { IRoute } from 'types/Route';

// const FaqScreen = lazy(() => import('modules/faq/faq.screen'));
// const MessageScreen = lazy(() => import('modules/faq/message/message.screen'));
const ContentLayout = lazy(() => import('../../libraries/layouts/content.layout'));
// const DetailMessageScreen = lazy(() => import('modules/faq/message/detail-message.screen'));
const IntroduceScreen = lazy(() => import('modules/introduce/introduce.screen'));
const IntroduceDetailScreen = lazy(() => import('modules/introduce/introduce-detail.screen'));
const PolicyScreen = lazy(() => import('modules/policy/policy.screen'));
const PolicyDetailScreen = lazy(() => import('modules/policy/policy-detail.screen'));
const BlogScreen = lazy(() => import('modules/blog/blog.screen'));
const BlogDetailScreen = lazy(() => import('modules/blog/blog-detail.screen'));
const NewsScreen = lazy(() => import('modules/news/news.screen'));
const PartnerScreen = lazy(() => import('modules/partner/partner.screen'));

export const FaqRoute: IRoute = {
    path: '/support',
    name: 'faq.customerCareCenter',
    component: ContentLayout,
    icon: <ReadOutlined />,
    existSubMenu: true,
    routes: [
        // {
        //     path: '/faq',
        //     name: 'faq.menu',
        //     component: FaqScreen,
        //     exact: true
        // },
        {
            path: '/support/introduce',
            name: 'introduce.menu',
            component: IntroduceScreen,
            exact: true
        },
        {
            path: '/support/introduce/add',
            name: 'introduce.add',
            component: IntroduceDetailScreen,
            exact: true,
            hideInMenu: true
        },
        {
            path: '/support/introduce/edit/:id',
            name: 'introduce.edit',
            component: IntroduceDetailScreen,
            exact: true,
            hideInMenu: true
        },
        {
            path: '/support/policy',
            name: 'policy.menu',
            component: PolicyScreen,
            exact: true
        },
        {
            path: '/support/policy/add',
            name: 'policy.add',
            component: PolicyDetailScreen,
            exact: true,
            hideInMenu: true
        },
        {
            path: '/support/policy/edit/:id',
            name: 'policy.edit',
            component: PolicyDetailScreen,
            exact: true,
            hideInMenu: true
        },
        {
            path: '/support/blog',
            name: 'blog.menu',
            component: BlogScreen,
            exact: true
        },
        {
            path: '/support/blog/add',
            name: 'blog.add',
            component: BlogDetailScreen,
            exact: true,
            hideInMenu: true
        },
        {
            path: '/support/blog/edit/:id',
            name: 'blog.edit',
            component: BlogDetailScreen,
            exact: true,
            hideInMenu: true
        },
        {
            path: '/support/news',
            name: 'news.menu',
            component: NewsScreen,
            exact: true
        },
        {
            path: '/support/partner',
            name: 'partner.menu',
            component: PartnerScreen,
            exact: true
        },
        // {
        //     path: '/faq/message',
        //     name: 'message.title',
        //     component: MessageScreen,
        //     exact: true
        // },
        // {
        //     path: '/faq/message/:id',
        //     name: 'message.titleDetail',
        //     component: DetailMessageScreen,
        //     exact: true,
        //     hideInMenu: true
        // }
    ]
};
