import { CrownOutlined } from '@ant-design/icons';
import React, { lazy } from 'react';
import { IRoute } from 'types/Route';

const RankScreen = lazy(() => import('modules/rank/rank.screen'));

const ContentLayout = lazy(() => import('../../libraries/layouts/content.layout'));

const RankRoute: IRoute = {
    path: '/rank',
    name: 'rank.menu',
    component: ContentLayout,
    icon: <CrownOutlined />,
    routes: [
        {
            path: '/rank',
            name: 'rank.title',
            component: RankScreen,
            exact: true
        },
    ]
};

export default RankRoute;
