import { GiftOutlined } from '@ant-design/icons';
import React, { lazy } from 'react';
import { IRoute } from 'types/Route';

const SaleScreen = lazy(() => import('modules/sales/sale.screen'));

const ContentLayout = lazy(() => import('../../libraries/layouts/content.layout'));

const SalesRoute: IRoute = {
    path: '/sales',
    name: 'sale.menu',
    component: ContentLayout,
    icon: <GiftOutlined />,
    routes: [
        {
            path: '/sales',
            name: 'sale.title',
            component: SaleScreen,
            exact: true
        }
    ]
};

export default SalesRoute;
