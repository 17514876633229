import MessagesRoute from './routes/messages.route';
import WarehouseRoute from './routes/warehouse.route';
import { HomeOutlined } from '@ant-design/icons';
import React, { lazy } from 'react';
import { IRoute } from 'types/Route';
import { PATH_HOME } from '.';
import * as routes from './routes';
import CollectionRoute from './routes/collection.route';
import { CouponRoute } from './routes/coupon.route';
import SalesRoute from './routes/sales.route';
import RankRoute from './routes/rank.route';
import ReportRoute from './routes/report.route';
import AuthorizationRoute from './routes/authorization.route';
import PaymentRoute from './routes/payment.route';
import { SettingRoute } from './routes';
import { langding_page_Route } from './routes/landing_page';

const MainLayout = lazy(() => import('../libraries/layouts/main.layout'));
const HomeScreen = lazy(() => import('../modules/home/home.screen'));

const RouteConfigs: IRoute[] = [
    routes.AuthRoute,
    {
        path: PATH_HOME,
        name: 'menu_home',
        component: MainLayout,
        hideInMenu: true,
        routes: [
            {
                path: PATH_HOME,
                name: 'menu_home',
                icon: <HomeOutlined />,
                exact: true,
                component: HomeScreen
            },
            WarehouseRoute,
            routes.ProductRoute,
            routes.OrderManagerRoute,
            // MessagesRoute,
            routes.AccountManagerRoute,
            // SaleRoute,   
            SalesRoute,
            // CollectionRoute,
            CouponRoute,
            RankRoute,
            routes.FaqRoute,
            langding_page_Route,
            // routes.FooterRoute,
            // routes.BannerRoute
            // AuthorizationRoute,
            // ReportRoute,
            PaymentRoute,
            MessagesRoute,
            SettingRoute,
        ]
    }
];

export default RouteConfigs;
