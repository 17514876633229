/* 
  Created by vungbt at 06-20-2022 22:35:20
  Module warehouse
*/
import { FileDoneOutlined } from '@ant-design/icons';
import React, { lazy } from 'react';
import { IRoute } from 'types/Route';

// bill ( hoá đơn )
// const BillScreen = lazy(() => import('modules/warehouse/bill/bill.screen'));
// const BillDetailScreen = lazy(() => import('modules/warehouse/bill/bill-detail.screen'));

// infrastructure ( cở sở vật chất )
// const InfrastructureScreen = lazy(
//     () => import('modules/warehouse/infrastructure/infrastructure.screen')
// );
// const InfrastructureDetailScreen = lazy(
//     () => import('modules/warehouse/infrastructure/infrastructure-detail.screen')
// );

// production plan ( kế hoạch sản xuất )
const ProductionPlanScreen = lazy(
    () => import('modules/warehouse/production-plan/production-plan.screen')
);

// Quản lý kho
const StoreHouseScreen = lazy(() => import('modules/warehouse/storehouse/storehouse.screen'));
const StoreHouseDetailScreen = lazy(
    () => import('modules/warehouse/storehouse/storehouse-detail.screen')
);

// // Phiếu xuất kho
// const DeliveryScreen = lazy(() => import('modules/warehouse/delivery/delivery.screen'));
// const ConfirmProduct = lazy(() => import('modules/warehouse/delivery/confirm-product.screen'));

// Thiết kế sản phẩm
// const ProductDesignScreen = lazy(
//     () => import('modules/warehouse/product-design/product-design.screen')
// );

const ContentLayout = lazy(() => import('../../libraries/layouts/content.layout'));

const SupplierScreen = lazy(() => import('modules/warehouse/supplier/supplier.screen'));

// const LabelManagementScreen = lazy(() => import('modules/warehouse/lable-management/label.screen'));

const ShippingScreen = lazy(() => import('modules/warehouse/shipping/shipping.screen'));

const AddShippingScreen = lazy(() => import('modules/warehouse/shipping/add-shipping.screen'));

const CreateSessionScreen = lazy(() => import('modules/warehouse/storehouse/create-session.screen'));

const WarehouseRoute: IRoute = {
    path: '/warehouse',
    name: 'warehouse.menu',
    component: ContentLayout,
    icon: <FileDoneOutlined />,
    existSubMenu: true,
    routes: [
        // Quản lý nhà sản xuất
        {
            path: '/warehouse/supplier',
            name: 'warehouse.supplier.menu',
            component: SupplierScreen,
            exact: true
        },

        // Thiết kế sản phẩm
        // {
        //     path: '/warehouse/product-design',
        //     name: 'warehouse.productDesign.menu',
        //     component: ProductDesignScreen,
        //     exact: true
        // },

        // production plan ( kế hoặc sản xuất )
        {
            path: '/warehouse/production-plan',
            name: 'warehouse.productionPlan.menu',
            component: ProductionPlanScreen,
            exact: true
        },

        // Quản lý kho
        {
            path: '/warehouse/storehouse',
            name: 'warehouse.storeHouse.menu',
            component: StoreHouseScreen,
            exact: true
        },
        {
            path: '/warehouse/storehouse/add',
            name: 'warehouse.storeHouse.add',
            component: StoreHouseDetailScreen,
            hideInMenu: true,
            exact: true
        },
        {
            path: '/warehouse/storehouse/create-session/:id',
            name: 'warehouse.storeHouse.create_session',
            component: CreateSessionScreen,
            hideInMenu: true,
            exact: true
        },
        {
            path: '/warehouse/storehouse/:id',
            name: 'warehouse.storeHouse.edit',
            component: StoreHouseDetailScreen,
            hideInMenu: true,
            exact: true
        },
        

        // infrastructure ( cở sở vật chất )
        // {
        //     path: '/warehouse/infrastructure',
        //     name: 'warehouse.infrastructure.menu',
        //     component: InfrastructureScreen,
        //     exact: true
        // },
        // {
        //     path: '/warehouse/infrastructure/view/:id',
        //     name: 'warehouse.infrastructure.infrastructureDetail',
        //     component: InfrastructureDetailScreen,
        //     hideInMenu: true,
        //     exact: true
        // },

        // Hóa đơn thanh toán
        // {
        //     path: '/warehouse/bill',
        //     name: 'warehouse.bill.menu',
        //     component: BillScreen,
        //     exact: true
        // },
        // {
        //     path: '/warehouse/bill/view/:id',
        //     name: 'warehouse.bill.billDetail',
        //     component: BillDetailScreen,
        //     hideInMenu: true,
        //     exact: true
        // },
        // {
        //     path: '/warehouse/label',
        //     name: 'warehouse.label.menu',
        //     component: LabelManagementScreen,
        //     exact: true
        // },

        // Phiếu xuất kho
        // {
        //     path: '/warehouse/delivery',
        //     name: 'warehouse.delivery.bill',
        //     component: ContentLayout,
        //     icon: <FileDoneOutlined />,
        //     existSubMenu: true,
        //     routes: [
        //         {
        //             name: 'warehouse.delivery.print',
        //             path: '/warehouse/delivery/print',
        //             component: DeliveryScreen,
        //             exact: true
        //         },
        //         {
        //             name: 'warehouse.delivery.confirm',
        //             path: '/warehouse/delivery/confirm',
        //             component: ConfirmProduct,
        //             exact: true
        //         }
        //     ]
        // },

        // Chuyển hàng
        // {
        //     path: '/warehouse/shipping',
        //     name: 'shipping.title',
        //     component: ShippingScreen,
        //     exact: true
        // },
        // {
        //     path: '/warehouse/shipping/add',
        //     name: 'shipping.textAdd',
        //     component: AddShippingScreen,
        //     hideInMenu: true,
        //     exact: true
        // },
        // {
        //     path: '/warehouse/shipping/edit/:id',
        //     name: 'shipping.textEdit',
        //     component: AddShippingScreen,
        //     hideInMenu: true,
        //     exact: true
        // }
    ]
};

export default WarehouseRoute;
